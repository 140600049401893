<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="handleSubmit">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Permissions</h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <Checkbox
                  field="permissions"
                  :list="permissions"
                  v-model="payload.permissions"
                />
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-12">
                <router-link :to="{ name: 'users' }" class="btn btn-danger"
                  >Cancel</router-link
                >
                <SubmitButton label="Save" class="btn btn-info ml-1" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Checkbox from "@/components/Form/Checkbox";
import SubmitButton from "@/components/SubmitButton";

export default {
  props: {
    id: {
      required: true,
      type: [String, Number]
    }
  },
  components: { Checkbox, SubmitButton },
  beforeRouteUpdate(to, from, next) {
    this.fetchUser(to.params.id)
      .then(() => {
        this.payload.permissions = this.user.permissions.map(
          permission => permission.name
        );
      })
      .finally(this.stopFetching);
    next();
  },
  created() {
    this.fetchUser(this.id)
      .then(() => {
        this.payload.permissions = this.user.permissions.map(
          permission => permission.name
        );
      })
      .finally(this.stopFetching);
  },
  computed: {
    ...mapGetters("user", ["user"]),
    permissions() {
      return [
        { value: "manage_users", label: this.$gettext("Manage Users") },
        { value: "manage_companies", label: this.$gettext("Manage Companies") },
        { value: "manage_offers", label: this.$gettext("Manage Offers") },
        {
          value: "manage_candidates",
          label: this.$gettext("Manage Candidates")
        },
        {
          value: "manage_subscriptions",
          label: this.$gettext("Manage Subscriptions")
        },
        {
          value: "manage_credit_cards",
          label: this.$gettext("Manage Credit Cards")
        }
      ];
    }
  },
  data: () => ({
    payload: {
      permissions: []
    }
  }),
  methods: {
    ...mapActions("user", ["updateUser", "fetchUser"]),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      const payload = { id: this.id, ...this.payload };
      this.submit(() => this.updateUser(payload)).then(() =>
        this.$router.push({ name: "users" })
      );
    }
  }
};
</script>
