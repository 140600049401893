<template>
  <div>
    <div
      v-for="item in list"
      :key="item.value"
      class="custom-control custom-checkbox pmd-checkbox"
    >
      <input
        v-model="inputValue"
        class="custom-control-input"
        type="checkbox"
        :value="item.value"
        :id="`checkbox-${item.value}`"
      />
      <label
        class="custom-control-label pmd-checkbox-ripple-effect"
        :for="`checkbox-${item.value}`"
      >
        {{ item.label }}
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Array,
      require: false
    },
    field: {
      type: String,
      require: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters("form", ["getFieldError", "fieldHasError"]),
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
